import { getCookies, deleteCookie } from "cookies-next";
import { useRouter } from "next/router";
import { useEffect } from "react";
import AuthLayout from "src/components/auth/layout";
import { TAuthOptionsProps } from "src/components/auth/layout/props";
import AuthLoginForm from "src/components/auth/login-form";
import AuthRegisterForm from "src/components/auth/register-form";
import ResetForm from "src/components/auth/reset-form";

export default function AuthMethodPage() {
  const { query } = useRouter();

  const method = query.method as TAuthOptionsProps;

  function clearCacheOnMount() {
    if (typeof window != "undefined") {
      localStorage.clear();
      const cookies = getCookies();
      for (const cookie in cookies) {
        deleteCookie(cookie);
      }

      deleteCookie("__Secure-next-auth.session-token");
      deleteCookie("__Secure-next-auth.callback-url");
      deleteCookie("__Host-next-auth.csrf-token");
      deleteCookie("next-auth.session-token");
    }
  }

  useEffect(clearCacheOnMount, []);

  function renderContent(): JSX.Element {
    switch (query.method as TAuthOptionsProps) {
      case "login":
        return (
          <div className="login" style={{ padding: "24px", width: "100%" }}>
            <AuthLoginForm />
          </div>
        );
      case "cadastro":
        return (
          <div
            className="register"
            style={{
              padding: "24px",
              width: "100%",
              maxHeight: "100%",
              overflowY: "auto",
            }}
          >
            <AuthRegisterForm />
          </div>
        );
      case "recuperar-senha":
        return (
          <div className="login" style={{ padding: "24px", width: "100%" }}>
            <ResetForm />
          </div>
        );
      default:
        return (
          <div className="login" style={{ padding: "24px", width: "100%" }}>
            <AuthLoginForm />
          </div>
        );
    }
  }
  return <AuthLayout method={method}>{renderContent()}</AuthLayout>;
}
