export function hasSpecialCharacter(password: string) {
  const regex = /[@#$!%*&]/;
  return regex.test(password);
}

export function hasUppercaseLetter(password: string) {
  const regex = /[A-Z]/;
  return regex.test(password);
}

export function hasLowercaseLetter(password: string) {
  const regex = /[a-z]/;
  return regex.test(password);
}

export function hasNumber(password: string) {
  const regex = /\d/;
  return regex.test(password);
}

export function hasMinimumLength(password: string) {
  return password.length >= 8;
}
