import { useState } from "react";
import * as Yup from "yup";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FormProvider, RHFTextField } from "src/components/hook-form";
import { Iconify, TextField } from "@sync/core";
import { useRegister } from "src/hooks/auth/mutations/useRegister";
import { RegisterRequestDto } from "src/hooks/auth/mutations/useRegister/props";
import Head from "next/head";
import { Container, Item, PasswordParams, PasswordWrapper } from "./styles";
import {
  hasLowercaseLetter,
  hasMinimumLength,
  hasNumber,
  hasSpecialCharacter,
  hasUppercaseLetter,
} from "./helpers";
import BasicSelectInput from "src/components/basic-select-input";
import { useGetProfessions, useGetSegments } from "src/hooks/auth";
import { SelectProps } from "src/components/basic-select-input/props";
import ReactInputMask from "react-input-mask";

export default function AuthRegisterForm() {
  const [showPassword, setShowPassword] = useState(false);
  const { data: professions, isLoading: loadingProfessions } =
    useGetProfessions();
  const { data: segments, isLoading: loadingSegments } = useGetSegments();

  const { mutate: register, isLoading } = useRegister();

  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Email must be a valid email address"),
    password: Yup.string().required("Password is required"),
  });

  const methods = useForm<RegisterRequestDto>({
    resolver: yupResolver(RegisterSchema),
  });

  const { handleSubmit, control, setValue } = methods;

  const password = useWatch({ control, name: "password" }) ?? "";
  const professionId = useWatch({ control, name: "professionId" }) ?? "";
  const segmentId = useWatch({ control, name: "segmentId" }) ?? "";

  const onSubmit = (data: RegisterRequestDto) => {
    register({
      ...data,
      isMobileNumberWhatsApp: true,
    });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Head>
        <title>Cadastro | Sync</title>
      </Head>
      <Container>
        <RHFTextField name="email" label="Email" />
        <Controller
          control={control}
          name="cpf"
          render={({ field, fieldState: { error } }) => (
            // @ts-ignore
            <ReactInputMask
              value={field.value as string}
              maskPlaceholder={null}
              placeholder="CPF"
              onChange={(value: any) => field.onChange(value)}
              mask="999.999.999-99"
            >
              <TextField
                fullWidth
                error={!!error}
                helperText={error?.message}
              />
            </ReactInputMask>
          )}
        />
        <Controller
          control={control}
          name="mobileNumber"
          render={({ field, fieldState: { error } }) => (
            // @ts-ignore
            <ReactInputMask
              value={field.value as string}
              maskPlaceholder={null}
              placeholder="Celular"
              onChange={(value: any) => field.onChange(value)}
              mask="(99) 99999-9999"
            >
              <TextField
                fullWidth
                error={!!error}
                helperText={error?.message}
              />
            </ReactInputMask>
          )}
        />
        <RHFTextField name="fullName" label="Nome Completo" />
        <BasicSelectInput
          options={
            (professions?.map((profession) => ({
              label: profession.displayText,
              value: Number(profession.value),
            })) as SelectProps[]) ?? []
          }
          setValue={(value: number) => setValue("professionId", value)}
          loading={loadingProfessions}
          placeholder="Qual é sua função ou cargo?"
          value={professionId ?? null}
        />
        <BasicSelectInput
          options={
            (segments?.map((segment) => ({
              label: segment.displayText,
              value: Number(segment.value),
            })) as SelectProps[]) ?? []
          }
          setValue={(value: number) => setValue("segmentId", value)}
          loading={loadingSegments}
          placeholder="Qual o segmento da empresa que você trabalha?"
          value={segmentId ?? null}
        />
        <RHFTextField
          name="password"
          label="Senha"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <PasswordWrapper>
          Requisitos de senha:
          <PasswordParams>
            <Item success={hasSpecialCharacter(password)}>
              Um caractere especial (Ex: #@$!%&*);
            </Item>
            <Item success={hasUppercaseLetter(password)}>
              Uma letra maiúscula
            </Item>
            <Item success={hasLowercaseLetter(password)}>
              Uma letra minúscula
            </Item>
            <Item success={hasNumber(password)}>Um número</Item>
            <Item success={hasMinimumLength(password)}>
              No mínimo 8 dígitos
            </Item>
          </PasswordParams>
        </PasswordWrapper>

        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={isLoading}
          disabled={
            isLoading ||
            !hasNumber(password) ||
            !hasMinimumLength(password) ||
            !hasSpecialCharacter(password) ||
            !hasUppercaseLetter(password) ||
            !hasLowercaseLetter(password)
          }
          sx={{
            bgcolor: "text.primary",
            color: (theme) =>
              theme.palette.mode === "light" ? "common.white" : "grey.800",
            "&:hover": {
              bgcolor: "text.primary",
              color: (theme) =>
                theme.palette.mode === "light" ? "common.white" : "grey.800",
            },
          }}
        >
          Criar uma conta
        </LoadingButton>
      </Container>
    </FormProvider>
  );
}
