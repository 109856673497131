import { useQuery } from "@sync/utils";
import { AuthQueryKeys } from "../../queryKeys";
import { fetchGetSegments } from "./service";

export function useGetSegments() {
  const { isLoading, isError, refetch, data, error, fetchStatus } = useQuery(
    [AuthQueryKeys.GetSegments],
    fetchGetSegments
  );

  return {
    isLoading: isLoading && fetchStatus != "idle",
    isError,
    refetch,
    data,
    error,
  };
}
