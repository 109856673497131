// @mui
import {
  Container,
  Card,
  Wrapper,
  LinkComponent,
  AnimationWrapper,
  Circle,
} from "./styles";
import { LayoutProps, TAuthOptionsProps } from "./props";
import { Logo } from "@sync/core";
import { useRouter } from "next/router";
import { getDescription, getTitle } from "./utils";
import { useSpring, m as motion } from "framer-motion";
import { useRef } from "react";

const spring = {
  type: "spring",
  stiffness: 300,
  damping: 40,
};

export default function AuthLayout({ children, method }: LayoutProps) {
  const { push, pathname, query } = useRouter();
  const ref = useRef(null);

  function handleRoute(method: TAuthOptionsProps) {
    push(
      {
        pathname,
        query: {
          ...query,
          method,
        },
      },
      undefined,
      { shallow: false }
    );
  }

  const dx = useSpring(0, spring);
  const dy = useSpring(0, spring);

  function renderCard() {
    if (method == "login") {
      return (
        <Card isLogin={method === "login"}>
          <Wrapper>
            <Logo />
            <div className="title">{getTitle(method)}</div>
            <div className="description">{getDescription(method)}</div>
            {children}
          </Wrapper>
          <LinkComponent onClick={() => handleRoute("cadastro")}>
            Não tem uma conta? <strong>Cadastre-se</strong>
          </LinkComponent>
        </Card>
      );
    }

    return (
      <Card isLogin={method === "cadastro"}>
        <Wrapper>
          <Logo />
          <div className="title">{getTitle(method)}</div>
          <div className="description">{getDescription(method)}</div>
          {children}
        </Wrapper>
        <LinkComponent onClick={() => handleRoute("login")}>
          Já tem uma conta? <strong>Login</strong>
        </LinkComponent>
      </Card>
    );
  }

  return (
    <Container>
      <AnimationWrapper className="circles">
        {Array.from({ length: 10 }).map((_, index) => (
          <Circle key={index} />
        ))}
      </AnimationWrapper>
      <motion.div
        transition={spring}
        className="card"
        style={{
          perspective: "1200px",
          transformStyle: "preserve-3d",
          width: "100%",
          height: "100%",
          maxHeight: "80vh",
        }}
      >
        <motion.div
          ref={ref}
          transition={spring}
          style={{
            width: "100%",
            height: "100%",
            rotateX: dx,
            rotateY: dy,
          }}
        >
          <div
            style={{
              perspective: "1200px",
              transformStyle: "preserve-3d",
              width: "100%",
              height: "100%",
            }}
          >
            <motion.div
              animate={{ rotateY: method === "cadastro" ? -180 : 0 }}
              transition={spring}
              style={{
                width: "100%",
                height: "100%",
                zIndex: method === "cadastro" ? 0 : 1,
                backfaceVisibility: "hidden",
                position: "absolute",
              }}
            >
              {renderCard()}
            </motion.div>
            <motion.div
              initial={{ rotateY: 180 }}
              animate={{ rotateY: method === "cadastro" ? 0 : 180 }}
              transition={spring}
              style={{
                width: "100%",
                height: "100%",
                zIndex: method === "cadastro" ? 1 : 0,
                backfaceVisibility: "hidden",
                position: "absolute",
              }}
            >
              {renderCard()}
            </motion.div>
          </div>
        </motion.div>
      </motion.div>
    </Container>
  );
}
