import { useMutation } from "@sync/utils";
import { fetchResetPassword } from "./service";
import { useAuthStore } from "src/store/authStore";
import { useRouter } from "next/router";
import { useNotificationCenterStore } from "src/store/notificationCenterStore";

export function useResetPassword() {
  const { setShowReset } = useAuthStore();
  const { push } = useRouter();
  const { addToastNotification } = useNotificationCenterStore();

  function onSuccess() {
    addToastNotification({
      variant: "Success",
      description: "Verifique seu e-mail para a troca da senha",
    });

    setShowReset();
    push("/auth/login");
  }

  function onError(err: any) {
    addToastNotification({
      variant: "Error",
      description: err?.error?.message ?? "Algo deu errado, tente novamente.",
    });
  }

  const mutation = useMutation(fetchResetPassword, {
    onError,
    onSuccess,
  });

  return mutation;
}
