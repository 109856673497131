import { Auth0Callback, Auth0Error } from "auth0-js";
import { ResetPasswordRequestDto } from "./props";
import { Auth0Service } from "src/service/Auth0Service";
import { useNotificationCenterStore } from "src/store/notificationCenterStore";

export async function fetchResetPassword({ email }: ResetPasswordRequestDto) {
  try {
    Auth0Service.auth.changePassword(
      {
        email,
        connection: "Username-Password-Authentication",
      },
      function (
        error: Auth0Error | null,
        result: any
      ): Auth0Callback<any, Auth0Error> {
        if (error) {
          useNotificationCenterStore.getState().addToastNotification({
            description: error?.description,
            variant: "Error",
          });
          throw Error(error?.description);
        }

        return result;
      }
    );
  } catch (err: any) {
    return err;
  }
}
