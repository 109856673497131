import { useMutation, useQueryClient } from "@sync/utils";
import { fetchLogout } from "./service";
import { IntegradorQueryKeys } from "src/hooks/integrador/queryKeys";
import { useNotificationCenterStore } from "src/store/notificationCenterStore";

export function useLogout() {
  const queryClient = useQueryClient();

  const { addToastNotification } = useNotificationCenterStore();

  function onSuccess() {
    queryClient.invalidateQueries([IntegradorQueryKeys.UseSyncSession]);
    queryClient.invalidateQueries([IntegradorQueryKeys.UseIntegradorSession]);
  }

  function onError(err: any) {
    addToastNotification({
      variant: "Error",
      description: err?.error?.message ?? "Algo deu errado, tente novamente.",
    });
  }

  const mutation = useMutation(fetchLogout, {
    onError,
    onSuccess,
  });

  return mutation;
}
