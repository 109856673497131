import { UseLogoutProps } from "./props";
import { Auth0Service } from "src/service/Auth0Service";

export async function fetchLogout({ clientID, returnTo }: UseLogoutProps) {
  try {
    Auth0Service.auth.logout({
      clientID,
      returnTo,
    });
  } catch (err: any) {
    return err;
  }
}
