import auth0 from "auth0-js";
import { AUTH0_USER_SCOPE } from "./config";

export abstract class Auth0Service {
  static auth = new auth0.WebAuth({
    domain: process.env.AUTH0_DOMAIN as string,
    clientID: process.env.AUTH0_CLIENT_ID as string,
    scope: AUTH0_USER_SCOPE,
    redirectUri: process.env.BASE_ADDRESS,
  });
}
