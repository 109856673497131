import { TAuthOptionsProps } from "./props";

export function getTitle(method: TAuthOptionsProps) {
  switch (method) {
    case "login":
      return "Bem-vindo de volta!";
    case "cadastro":
      return "Cadastro";
  }
}

export function getDescription(method: TAuthOptionsProps) {
  switch (method) {
    case "login":
      return "Preencha os campos abaixo para continuar.";
    case "cadastro":
      return "Com um Login você faz tudo em sua empresa.";
  }
}
