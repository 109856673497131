/* eslint-disable react-hooks/rules-of-hooks */

import { RegisterRequestDto } from "./props";
import { Auth0Service } from "src/service/Auth0Service";
import {
  AUTH0_LOGIN_RESPONSE_TYPE,
  AUTH0_REALM,
  AUTH0_USER_SCOPE,
} from "src/service/Auth0Service/config";
import { Auth0Callback, Auth0Error } from "auth0-js";
import { deleteCookie, setCookie } from "cookies-next";
import {
  useClientSync as _useClientSync,
  syncSessionKeys,
} from "src/hooks/useClientSync";
import { setLocalStorage } from "src/utils/authentication";
import { useNotificationCenterStore } from "src/store/notificationCenterStore";

async function registerAuth0({ email, password, ...rest }: RegisterRequestDto) {
  const response = Auth0Service.auth.signup(
    {
      email: email.trim(),
      password: password.trim(),
      scope: AUTH0_USER_SCOPE,
      connection: AUTH0_REALM,
    },
    function (error: Auth0Error | null, result: any) {
      if (error) {
        useNotificationCenterStore.getState().addToastNotification({
          description:
            error?.code == "invalid_signup"
              ? "Conta já registrada com o e-mail informado, faça login no aplicativo."
              : error?.description,
          variant: "Error",
        });
        throw new Error(error?.description);
      }

      Auth0Service.auth.login(
        {
          email: email.trim(),
          password: password.trim(),
          realm: AUTH0_REALM,
          redirectUri: process.env.BASE_ADDRESS,
          responseType: AUTH0_LOGIN_RESPONSE_TYPE,
          onRedirecting(done) {
            setCookie("app-sync-auth-token", true);
            setCookie("confirm-mail", true);
            done();
          },
        },
        function (
          error: Auth0Error | null,
          result: any
        ): Auth0Callback<any, Auth0Error> {
          if (error) {
            useNotificationCenterStore.getState().addToastNotification({
              description:
                error?.code == "invalid_signup"
                  ? "Conta já registrada com o e-mail informado, faça login no aplicativo."
                  : error?.description,
              variant: "Error",
            });
            throw new Error(error?.description);
          }

          return result;
        }
      );
    }
  );
  return response;
}

export async function fetchRegister({
  email,
  password,
  ...rest
}: RegisterRequestDto) {
  const { user } = _useClientSync(true);

  try {
    const data = await user.completeRegistration({
      requestBody: {
        ...rest,
        emailAddress: email.trim(),
      },
    });

    if (data?.error?.message) {
      useNotificationCenterStore.getState().addToastNotification({
        description:
          data?.error?.message ??
          "Houve um erro, revise sua informação ou tente novamente",
        variant: "Error",
      });
      return;
    }

    setLocalStorage(syncSessionKeys.user, data.result);
    deleteCookie("confirm-mail");

    const response = await registerAuth0({ email, password, ...rest });

    return response;
  } catch (err: any) {
    const parsed = JSON.parse(JSON.stringify(err));
    const error = parsed?.body?.error?.message;

    switch (true) {
      case error?.includes("usado") == true:
        // eslint-disable-next-line no-case-declarations
        const response = await registerAuth0({ email, password, ...rest });
        return response;
      default:
        useNotificationCenterStore.getState().addToastNotification({
          description: error?.error?.message ?? "Algo deu errado",
          variant: "Error",
        });
    }
  }
}
