import { create } from "zustand";

import { AuthAction, AuthState } from "./props";

export const useAuthStore = create<AuthState & AuthAction>((set) => ({
  showResetPassword: false,
  email: "",
  setShowReset(payload) {
    set((state) => ({
      showResetPassword: !state.showResetPassword,
    }));
  },
  setEmail(e) {
    set({
      email: e.target.value,
    });
  },
}));
