import { AjaxResponseOfListOfVehicleExpensesDto } from "@sync/utils/src/integrador/api";
import { useMutation } from "@sync/utils";
import { fetchRegister } from "./service";
import { useNotificationCenterStore } from "src/store/notificationCenterStore";

export function useRegister() {
  const { addToastNotification } = useNotificationCenterStore();

  function onError(err: AjaxResponseOfListOfVehicleExpensesDto) {
    addToastNotification({
      variant: "Error",
      description: err?.error?.message ?? "Algo deu errado, tente novamente.",
    });
  }

  const mutation = useMutation(fetchRegister, {
    onError,
  });

  return mutation;
}
