import { styled } from "@sync/core";
import { ItemProps } from "./props";

export const PasswordWrapper = styled("div")`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: 600;
`;

export const PasswordParams = styled("ul")`
  display: flex;
  flex-direction: column;
  padding-left: 36px;
`;

export const Item = styled("li")<ItemProps>`
  font-size: 16px;
  transition: color 0.3s;
  color: ${({ theme, success }) =>
    success ? theme.palette.success.main : theme.palette.text.secondary};
`;

export const Container = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  overflow-y: auto;
`;
