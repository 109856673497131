import {
  AjaxResponseOfListOfComboboxItemDto,
  CancelablePromise,
} from "@sync/utils/src/sync/api";
import { useClientSync as _useClientSync } from "src/hooks/useClientSync";

export async function fetchGetProfessions() {
  const { professions } = _useClientSync(true);

  const response: CancelablePromise<AjaxResponseOfListOfComboboxItemDto> =
    professions.getProfessionsCombo({});
  return (await response).result;
}
