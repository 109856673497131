import { Auth0Callback, Auth0Error } from "auth0-js";
import { LoginRequestDto } from "./props";
import { Auth0Service } from "src/service/Auth0Service";
import {
  AUTH0_LOGIN_RESPONSE_TYPE,
  AUTH0_REALM,
} from "src/service/Auth0Service/config";
import { setCookie } from "cookies-next";
import { useNotificationCenterStore } from "src/store/notificationCenterStore";

export async function fetchLogin({ email, password }: LoginRequestDto) {
  try {
    Auth0Service.auth.login(
      {
        email: email.trim(),
        password: password.trim(),
        realm: AUTH0_REALM,
        redirectUri: process.env.BASE_ADDRESS,
        responseType: AUTH0_LOGIN_RESPONSE_TYPE,
        onRedirecting(done) {
          setCookie("app-sync-auth-token", true);
          done();
        },
      },
      function (
        error: Auth0Error | null,
        result: any
      ): Auth0Callback<any, Auth0Error> {
        if (error) {
          useNotificationCenterStore.getState().addToastNotification({
            description: error?.description,
            variant: "Error",
          });
        }

        return result;
      }
    );
  } catch (err: any) {
    return err;
  }
}
