/* eslint-disable arrow-body-style */

import NextLink from "next/link";
import { forwardRef } from "react";
// @mui
import { Box, BoxProps } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<any, Props>(({ disabledLink = false, sx }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box ref={ref} sx={{ width: 80, height: 40, cursor: "pointer", ...sx }}>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 512 512"
      >
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>

        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
          <path
            fill="url(#BG1)"
            d="M183.168 285.573l-2.918 5.298-2.973 5.363-2.846 5.095-2.274 4.043-2.186 3.857-2.506 4.383-1.6 2.774-2.294 3.939-1.099 1.869-1.416 2.388-1.025 1.713-1.317 2.18-.95 1.558-1.514 2.447-.866 1.38-.833 1.312-.802 1.246-.77 1.18-.739 1.111-.935 1.38-.664.956-.425.6-.41.572-.59.8-.376.497-.537.69-.171.214c-10.76 13.37-22.496 23.493-36.93 29.334-30.346 14.262-68.07 14.929-97.202-2.704l72.347-124.682 2.8-1.72c49.257-29.326 73.08 1.117 94.02 40.927z"
          />
          <path
            fill="url(#BG2)"
            d="M444.31 229.726c-46.27-80.956-94.1-157.228-149.043-45.344-7.516 14.384-12.995 42.337-25.267 42.337v-.142c-12.272 0-17.75-27.953-25.265-42.337C189.79 72.356 141.96 148.628 95.69 229.584c-3.483 6.106-6.828 11.932-9.69 16.996 106.038-67.127 97.11 135.667 184 137.278V384c86.891-1.611 77.962-204.405 184-137.28-2.86-5.062-6.206-10.888-9.69-16.994"
          />
          <path
            fill="url(#BG3)"
            d="M450 384c26.509 0 48-21.491 48-48s-21.491-48-48-48-48 21.491-48 48 21.491 48 48 48"
          />
        </g>
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 495.95 170.85"
        width="100%"
        height="100%"
        // {...props}
      >
        <defs>
          <style>{`.cls-1{fill:${PRIMARY_DARK};fill-rule:evenodd}`}</style>
        </defs>
        <g id="Camada_2" data-name="Camada 2">
          <g id="Layer_1" data-name="Layer 1">
            <path
              className="cls-1"
              d="M54.62 100.24c-13.78 0-23.55-5.52-23.55-13.53H0C0 114 24.81 129.3 53.87 129.3c32.32 0 54.12-13.53 53.87-40.34 0-18-11.78-27.81-25.06-31.82-6.76-2.25-15-4-24.8-5.76-16.79-2.76-24.05-5-24.05-11 0-6.76 8-11.52 18.79-11.52 11 0 20.54 5.26 20.54 12.28h31.07c0-25.81-21-41.1-51.11-41.1C24.05 0 1.25 16.05 1.25 37.85c0 18.79 11 28.31 24.06 32.57a216.38 216.38 0 0 0 24.55 5.51c17.79 3 25.31 5 25.31 12.28 0 7.02-7.52 12.03-20.55 12.03ZM239.07 2.91H203.7l-27.19 85.17H176L147.88 2.91h-36.33L155.1 119.6a20.55 20.55 0 0 1 1.44 7.7 17 17 0 0 1-3.25 10.1q-3.24 4.57-10 5.3a71.14 71.14 0 0 1-10.11-.25l-9.86-1v28.15c3.52.32 7 .61 10.46.85s6.94.36 10.47.36q17.55 0 27.43-6.5t15.4-21.65ZM277.71 61.4c0-20.29 6.77-29.57 22.55-29.57s22.55 9.28 22.55 29.57v65.9h32.57V61.4c0-40.84-21.79-61.4-55.12-61.4s-55.12 20.56-55.12 61.4v65.9h32.57ZM433.81 129.3a62.89 62.89 0 0 0 38.84-12.78A63.42 63.42 0 0 0 495.7 83.7h-35.33a31 31 0 0 1-26.31 13.78c-9.27 0-16.79-3-23.05-9.27s-9.27-14.28-9.27-23.55 3-17 9.27-23.3a32.45 32.45 0 0 1 50.36 5.51H496c-3.76-13.78-11.53-24.81-23.05-33.58A62 62 0 0 0 433.81 0C399-.74 368.42 29.83 369.17 64.66c-.75 34.83 29.83 65.39 64.64 64.64Z"
            />
          </g>
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <NextLink href="/">{logo}</NextLink>;
});

export default Logo;
