import { useForm } from "react-hook-form";
import { Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { TextField, Typography } from "@sync/core";
import { useResetPassword } from "src/hooks/auth";
import { LoginRequestDto } from "src/hooks/auth/mutations/useLogin/props";
import { useAuthStore } from "src/store/authStore";
import { AnimatePresence, m as motion } from "framer-motion";
import { varFade } from "src/components/animate";
import { FormProvider } from "src/components/hook-form";
import Head from "next/head";
import { useNotificationCenterStore } from "src/store/notificationCenterStore";

export default function ResetForm() {
  const { email, setEmail } = useAuthStore();
  const { addToastNotification } = useNotificationCenterStore();

  const { mutate: resetPassword, isLoading: loadingReset } = useResetPassword();

  const methods = useForm<LoginRequestDto>({});

  const { handleSubmit } = methods;

  const onSubmit = async (data: LoginRequestDto) => {
    if (email.length == 0) {
      addToastNotification({
        variant: "Error",
        description: "O campo e-mail é obrigatório",
      });
      return;
    }

    if (
      !new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/).test(
        email
      )
    ) {
      addToastNotification({
        variant: "Error",
        description: "O e-mail deve ser válido",
      });
      return;
    }

    resetPassword({ email });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Head>
        <title>Login | Sync</title>
      </Head>
      <AnimatePresence exitBeforeEnter>
        <motion.div {...varFade().inUp}>
          <Stack spacing={3} sx={{ mb: 3 }}>
            <Typography
              color="text.secondary"
              sx={{ width: "100%", textAlign: "center" }}
            >
              Digite seu e-mail para recuperação de senha
            </Typography>
            <TextField label="E-mail" value={email} onChange={setEmail} />
          </Stack>
        </motion.div>
      </AnimatePresence>
      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={loadingReset}
        disabled={loadingReset}
        sx={{
          bgcolor: "text.primary",
          color: (theme) =>
            theme.palette.mode === "light" ? "common.white" : "grey.800",
          "&:hover": {
            bgcolor: "text.primary",
            color: (theme) =>
              theme.palette.mode === "light" ? "common.white" : "grey.800",
          },
        }}
      >
        Enviar e-mail
      </LoadingButton>
    </FormProvider>
  );
}
